/*CORE*/
import React, {useState, useEffect, useCallback} from 'react'
/*LIBS*/
import {Typography, Form, Input, Button, message, Upload, Switch, Select} from 'antd'
import {PlusOutlined} from '@ant-design/icons/lib'
import {FieldData, Store} from 'rc-field-form/lib/interface'
import {useClipboard} from 'use-clipboard-copy'
/*ASSETS*/
import {ReactComponent as CopyIcon} from 'assets/img/copy.svg'
/*HOOKS*/
import useUserPermission from 'hooks/useUserPermission'
/*COMPONENTS*/
import ColorPicker from '../../../MiniApps/components/ColorPicker/ColorPicker'
import LogoCropper from '../../../components/LogoCropper/LogoCropper'
import InfoTooltip from '../../../../common/InfoTooltip'
import AccessControl from '../../../../common/AccessControl'
/*UTILS*/
import {beforeUpload, handleChange, hasErrors, normalizeFields, generateQR} from 'utils/utils'
/*CONSTANTS*/
import {DOCS_URL, Permissions} from 'utils/constants'
/*TYPES*/
import {IFormData, ISuperApp} from 'types'
/*STYLES*/
import styles from './SuperAppForm.module.scss'

const {Title, Text} = Typography

interface Props {
  app: ISuperApp,
  isSaving: boolean,
  onSubmit: (values: Store) => void
}

const SuperAppForm = ({app, isSaving, onSubmit}: Props) => {
  const [form] = Form.useForm()
  const {setFieldsValue} = form
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [qrImage, setQrImage] = useState('')
  const [logoUrl, setLogoUrl] = useState(app.logo)
  const [showImageCropModal, setShowImageCropModal] = useState(false)
  const [isShareColors, setIsShareColors] = useState(false)
  const [isAppboxoConnectEnabled, setIsAppboxoConnectEnabled] = useState(false)
  const [isPaymentsEnabled, setIsPaymentsEnabled] = useState(false)
  const user = useUserPermission()
  const clipboard = useClipboard({
    onSuccess() {
      message.success('Copied successfully')
    },
    onError() {
      message.error('Failed to copy text')
    }
  })

  useEffect(() => {
    app.single_sign_on_integrated && setIsAppboxoConnectEnabled(app.single_sign_on_integrated);
    app.payments_integrated && setIsPaymentsEnabled(app.payments_integrated);
  }, [app.single_sign_on_integrated, app.payments_integrated])

  useEffect(() => {
    !qrImage && generateQRImage()
    app.share_colors !== isShareColors && setIsShareColors(app.share_colors)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.share_colors])

  const generateQRImage = async () => {
    if (app) {
      const data = {
        secret_key: app.secret_key,
        client_id: app.client_id
      }
      const imageUrl = await generateQR(JSON.stringify(data))
      setQrImage(imageUrl)
    }
  }

  const handleSubmit = (values: Store) => {
    setSubmitDisabled(true)
    onSubmit({...app, ...values})
  }

  const createCopyHandler = useCallback((key: keyof typeof app) => (
    () => clipboard.copy(app[key])
  ), [app, clipboard])

  const hasChangesOrInvalid = (changedValues: FieldData[], allFields: FieldData[]) => {
    const values = normalizeFields(allFields)

    values.share_colors.value !== isShareColors && setIsShareColors(values.share_colors.value)
    setIsAppboxoConnectEnabled(values.single_sign_on_integrated.value)
    setIsPaymentsEnabled(values.payments_integrated.value)

    let disableButton: boolean
    if (values?.share_colors?.value && values.primary_color && values.secondary_color && values.tertiary_color) {
      disableButton =
        values.name.value === app.name &&
        values.primary_color.value === app.primary_color &&
        values.secondary_color.value === app.secondary_color &&
        values.tertiary_color.value === app.tertiary_color &&
        values.share_colors.value === app.share_colors &&
        logoUrl === app.logo &&
        values.hostapp_client_id.value === app.hostapp_client_id &&
        values.hostapp_secret_key.value === app.hostapp_secret_key &&
        values.white_listed_urls.value === app.whitelisted_urls &&
        values.whitelisted_ips.value === app.whitelisted_ips &&
        //SSO
        values.single_sign_on_integrated.value === app.single_sign_on_integrated &&
        values.single_sign_on_enabled.value === app.single_sign_on_enabled &&
        values.get_access_token_url.value === app.get_access_token_url &&
        values.get_user_data_url.value === app.get_user_data_url &&
        //Payments
        values.payments_integrated.value === app.payments_integrated &&
        values.payments_enabled.value === app.payments_enabled &&
        values.create_order_payment_url.value === app.create_order_payment_url &&
        values.get_order_payment_status_url.value === app.get_order_payment_status_url
    } else {
      disableButton =
        values.name.value === app.name &&
        values.share_colors.value === app.share_colors &&
        logoUrl === app.logo &&
        values.single_sign_on_integrated === app.single_sign_on_integrated &&
        values.single_sign_on_enabled === app.single_sign_on_enabled &&
        values.get_access_token_url === app.get_access_token_url &&
        values.get_user_data_url === app.get_user_data_url
    }

    setSubmitDisabled(disableButton || hasErrors(allFields))
  }

  const handleImageCrop = (data: IFormData) => {
    setShowImageCropModal(false)
    setLogoUrl(data.dataUrl)
    setFieldsValue({
      upload: data.dataBlob
    })
  }

  const handleCancelCrop = () => {
    setLogoUrl('')
    setShowImageCropModal(false)

    setFieldsValue({
      upload: null
    })
  }

  // Initial upload value
  const fileList = [{
    uid: '-1',
    name: `${app.name}.png`,
    status: 'done',
    url: app.logo,
  }]

  const uploadButton = (
    <div>
      <PlusOutlined/>
      <div className={styles['ant-upload-text']}>Upload</div>
    </div>
  )

  return (
    <div className={styles['super-app-form-wrapper']}>
      <Form
        form={form}
        layout='horizontal'
        onFinish={handleSubmit}
        onFieldsChange={hasChangesOrInvalid}
        initialValues={{
          name: app.name,
          primary_color: app.primary_color,
          secondary_color: app.secondary_color,
          tertiary_color: app.tertiary_color,
          share_colors: app.share_colors,
          hostapp_client_id: app.hostapp_client_id,
          hostapp_secret_key: app.hostapp_secret_key,
          whitelisted_urls: app.whitelisted_urls,
          whitelisted_ips: app.whitelisted_ips,
          //SSO
          single_sign_on_integrated: app.single_sign_on_integrated,
          single_sign_on_enabled: app.single_sign_on_enabled,
          get_access_token_url: app.get_access_token_url,
          get_user_data_url: app.get_user_data_url,
          //Payments
          payments_integrated: app.payments_integrated,
          payments_enabled: app.payments_enabled,
          create_order_payment_url: app.create_order_payment_url,
          get_order_payment_status_url: app.get_order_payment_status_url,
          upload: fileList
        }}
      >
        <div className={styles['super-app-form']}>
          <div>
            <div className={styles['super-app-form__section']}>
              <Title level={3}>Host app information</Title>
              <Form.Item
                label='Host app name'
                name='name'
                colon={false}
                rules={[{
                  required: true,
                  message: 'Please enter host app name',
                }]}
              >
                <Input
                  placeholder='Host app name'
                  size='large'
                  readOnly={!user.CAN_EDIT}
                />
              </Form.Item>
            </div>

            <div className={styles['super-app-form__section']}>
              <Title level={3}>Integration keys</Title>
              <p className={styles['integration-text']}>
                You need secret key and client id to set up Boxo Connect API integration to pass relevant data
                points.
                Refer to <a href={`${DOCS_URL}/hostapp/`} target='_blank' rel='noopener noreferrer'>host app
                documentation
              </a> for details.
              </p>

              <Form.Item label='Secret key' colon={false}>
                <Input
                  defaultValue={app.secret_key}
                  suffix={<CopyIcon className='copy-icon' onClick={createCopyHandler('secret_key')}/>}
                  readOnly
                />
              </Form.Item>
              <Form.Item label='Client ID' colon={false}>
                <Input
                  defaultValue={app.client_id}
                  suffix={<CopyIcon className='copy-icon' onClick={createCopyHandler('client_id')}/>}
                  readOnly
                />
              </Form.Item>
              <Form.Item label='Host-app client ID' name='hostapp_client_id' colon={false}>
                <Input
                  placeholder='Host-app client ID'
                  suffix={<CopyIcon className='copy-icon' onClick={createCopyHandler('hostapp_client_id')}/>}
                />
              </Form.Item>
              <Form.Item label='Host-app secret key' name={'hostapp_secret_key'} colon={false}>
                <Input
                  placeholder={'Host-app secret key'}
                  suffix={<CopyIcon className='copy-icon' onClick={createCopyHandler('hostapp_secret_key')}/>}
                />
              </Form.Item>

              <Form.Item
                name='whitelisted_urls'
                label={
                  <span className={styles['label-tooltip']}>
                    Whitelisted URLs
                  </span>
                }
                rules={[{
                  type: 'array',
                  message: 'Please enter valid URLs',
                  defaultField: {type: 'url', message: 'Please enter valid URLs',},
                }]}
                colon={false}
                hasFeedback
              >
                <Select
                  mode='tags'
                  placeholder='White Listed URLs'
                  dropdownStyle={{display: 'none'}}
                  className={styles['white-listed-urls-select']}
                />
              </Form.Item>

              <Form.Item
                name='whitelisted_ips'
                label={
                  <span className={styles['label-tooltip']}>
                    Whitelisted IPs
                  </span>
                }
                rules={[{
                  type: 'array'
                }]}
                colon={false}
                hasFeedback
              >
                <Select
                  mode='tags'
                  placeholder='Whitelisted IPs'
                  dropdownStyle={{display: 'none'}}
                  className={styles['white-listed-urls-select']}
                />
              </Form.Item>
            </div>

            <div className={styles['super-app-form__section']}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <Title level={3}>Boxo connect</Title>
                <Form.Item
                  style={{marginBottom: 11, marginLeft: 135}}
                  name='single_sign_on_integrated'
                  valuePropName='checked'
                  colon={false}
                >
                  <Switch/>
                </Form.Item>
              </div>

              {isAppboxoConnectEnabled && (
                <div>
                  <Form.Item
                    name='single_sign_on_enabled'
                    valuePropName='checked'
                    colon={false}
                    label={'Single sign on enabled'}
                  >
                    <Switch/>
                  </Form.Item>
                  <Form.Item label='Access token URL' name="get_access_token_url" colon={false}>
                    <Input
                      placeholder={'Access token URL'}
                      suffix={<CopyIcon className='copy-icon' onClick={createCopyHandler('get_access_token_url')}/>}
                    />
                  </Form.Item>
                  <Form.Item label='User data URL' name='get_user_data_url' colon={false}>
                    <Input
                      placeholder={'User data URL'}
                      suffix={<CopyIcon className='copy-icon' onClick={createCopyHandler('get_user_data_url')}/>}
                    />
                  </Form.Item>
                </div>
              )}
            </div>

            <div className={styles['super-app-form__section']}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <Title level={3}>Payments</Title>
                <Form.Item
                  style={{marginBottom: 11, marginLeft: 175}}
                  name='payments_integrated'
                  valuePropName='checked'
                  colon={false}
                >
                  <Switch/>
                </Form.Item>
              </div>

              {isPaymentsEnabled && (
                <div>
                  <Form.Item
                    name='payments_enabled'
                    valuePropName='checked'
                    colon={false}
                    label={'Payments enabled'}
                  >
                    <Switch/>
                  </Form.Item>
                  <Form.Item label='Create order payment URL' name="create_order_payment_url" colon={false}>
                    <Input
                      placeholder={'Create order payment URL'}
                      suffix={<CopyIcon className='copy-icon' onClick={createCopyHandler('create_order_payment_url')}/>}
                    />
                  </Form.Item>
                  <Form.Item label='Get order payment status URL' name='get_order_payment_status_url' colon={false}>
                    <Input
                      placeholder={'Get order payment status URL'}
                      suffix={<CopyIcon className='copy-icon' onClick={createCopyHandler('get_order_payment_status_url')}/>}
                    />
                  </Form.Item>
                </div>
              )}
            </div>

            <div className={styles['super-app-form__section']}>
              <Title level={3}>App icon</Title>
              <div style={{marginBottom: 24}}>Host app icon needs to be in PNG format.</div>
              <Form.Item
                name='upload'
                valuePropName='file'
                rules={[{
                  required: true,
                  message: 'Please upload host app icon',
                }]}
              >
                <Upload
                  disabled={!user.CAN_EDIT}
                  name='avatar'
                  accept='image/x-png'
                  listType='picture-card'
                  showUploadList={false}
                  beforeUpload={file => beforeUpload(file, (imageUrl: string) => setLogoUrl(imageUrl))}
                  onChange={file => handleChange(file, (fileInfo: { file: File, fileList: File[] }) => {
                    if (file.file.status !== 'done') {
                      setLogoUrl(app.logo)
                    } else if (fileInfo.fileList.length === 1) {
                      setLogoUrl(fileInfo.file.name)
                      setShowImageCropModal(true)
                    }
                  })}
                >
                  {logoUrl ? <img src={logoUrl} alt='avatar' style={{width: '100%'}}/> : uploadButton}
                </Upload>
              </Form.Item>
            </div>

            <div className={styles['super-app-form__section']}>
              <Title level={3}>White-labeling</Title>
              <Form.Item
                name='share_colors'
                valuePropName='checked'
                label={
                  <span className={styles['pass-colors-label']}>
                    Pass host app colors
                    <InfoTooltip
                      title="When turned on, miniapp can receive host app name,
                      logo and colors to white label its appearance to suite host app's style"
                    />
                  </span>
                }
                colon={false}
              >
                <Switch disabled={!user.CAN_EDIT}/>
              </Form.Item>
              {
                isShareColors &&
                  <div className={styles['super-app-form__share-colors']}>
                      <Form.Item
                          name='primary_color'
                      >
                          <ColorPicker
                              label='Primary'
                              readOnly={!user.CAN_EDIT}
                          />
                      </Form.Item>
                      <Form.Item
                          name='secondary_color'
                      >
                          <ColorPicker
                              label='Secondary'
                              readOnly={!user.CAN_EDIT}
                          />
                      </Form.Item>
                      <Form.Item
                          name='tertiary_color'
                      >
                          <ColorPicker
                              label='Tertiary'
                              readOnly={!user.CAN_EDIT}
                          />
                      </Form.Item>
                  </div>
              }
            </div>
          </div>

          <div className={styles['super-app-form__save-btn-wrapper']}>
            <AccessControl permission={Permissions.CAN_EDIT}>
              <Button
                type='primary'
                htmlType='submit'
                size='small'
                loading={isSaving}
                disabled={submitDisabled}
              >
                {isSaving ? 'Saving' : 'Save'}
              </Button>
            </AccessControl>
          </div>
        </div>
      </Form>

      <div className={styles['super-app-form']}>
        <div>
          <AccessControl permission={Permissions.IS_STAFF}>
            <div className={styles['super-app-form__section']}>
              <Title level={3}>Test authorization</Title>
              <p>Use DevApp application to read QR code and test authorization process</p>
              <img src={qrImage} alt='Integration keys'/>
            </div>
          </AccessControl>

          <div className={styles['super-app-form__section']}>
            <Title level={3}>Delete host app</Title>
            <Text>To delete host app, please send an email to <a
              href={`mailto:support@boxo.io?subject=Delete host app request!&body=Host app client ID: ${app.client_id}`}>support@boxo.io</a> from
              your business email.</Text>
          </div>
        </div>
      </div>

      <LogoCropper
        isModalVisible={showImageCropModal}
        imgUrl={logoUrl}
        onImageCrop={handleImageCrop}
        onCancelClick={handleCancelCrop}
      />
    </div>
  )
}

export default SuperAppForm
